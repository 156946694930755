import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { LocaleContext } from 'gatsby-theme-i18n'
import ButtonLink from 'components/ButtonLink'
import LinkWrap from 'components/LinkWrap'
import LoaderSvg from 'assets/svg/ui/loader.svg'

import axios from 'axios'
import { objToFormData } from 'js/utils'
import * as styles from './styles.module.scss'

const Component = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const locale = React.useContext(LocaleContext)

  const strings = {
    en: {
      password: 'Password',
      errorTokenIssue: 'Error: token issue.',
      success: 'Success: password updated.',
      backToLogin: 'Back to login',
      submit: 'Submit',
    },
    zh: {
      password: '密码',
      errorTokenIssue: '提示：未知错误。',
      success: '提示：密码已更改。',
      backToLogin: '返回登陆',
      submit: '提交',
    },
  }
  const tr = strings[locale]

  const onSubmit = (values) => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get('token')

    setError(null)
    setIsLoading(true)

    const errorTokenIssue = () => {
      setError(null)
      setTimeout(() => {
        setError('tokenIssue')
        setIsLoading(false)
      }, 500)
    }

    const success = () => {
      setError(null)
      setTimeout(() => {
        setError('success')
        setIsLoading(false)
      }, 500)
    }

    const url = process.env.GATSBY_PHP_URL

    const data = {
      function: 'changePassword',
      token: urlToken,
      newPassword: values.password,
    }
    const formData = objToFormData(data)

    axios.post(url, formData).then((response) => {
      if (response.data === 'errorNoUser') errorTokenIssue()
      if (response.data === 'errorNoToken') errorTokenIssue()
      if (response.data === 'success') success()
    })
  }

  return (
    <div className={styles.component}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='fields'>
              <div className='field-row'>
                <Field
                  name='password'
                  component='input'
                  type='password'
                  placeholder={tr.password}
                  required
                />
              </div>
            </div>
            <div className={'submit-wrap ' + (isLoading ? 'is-loading' : '')}>
              <div className='loader-wrap'>
                <ButtonLink
                  content={{
                    text: tr.submit,
                    linkType: 'submit',
                    isDisabled: isLoading,
                  }}
                />
                <div className='loader'>
                  <LoaderSvg />
                </div>
              </div>
            </div>
            <ul className='error-holder'>
              <li className='placeholder'>{tr.errorTokenIssue}</li>
              <li className={error === 'tokenIssue' ? 'show' : ''}>
                {tr.errorTokenIssue}
              </li>
              <li className={error === 'success' ? 'show' : ''}>
                {tr.success}
                <br />
                <LinkWrap to='/account'>{tr.backToLogin}</LinkWrap>
              </li>
            </ul>
          </form>
        )}
      />
    </div>
  )
}

export default Component
