import React from 'react'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import ChangePassword from 'blocks/ChangePassword/template'
import Seo from 'components/Seo'

import { LocaleContext } from 'gatsby-theme-i18n'

const Page = () => {
  const locale = React.useContext(LocaleContext)

  const strings = {
    en: {
      accounts: 'Accounts',
      changePassword: 'Change Password',
    },
    zh: {
      accounts: '账户',
      changePassword: '修改密码',
    },
  }
  const tr = strings[locale]

  const seo = {
    title: `${tr.accounts} - ${tr.changePassword}`,
  }
  const blockContent = {
    bannerTextOnly: {
      pretitle: tr.accounts,
      pretitleLink: '/account',
      title: tr.changePassword,
    },
  }

  return (
    <>
      <Seo content={seo} />
      <BannerTextOnly
        content={blockContent.bannerTextOnly}
        padBottomLess={true}
      />
      <ChangePassword />
    </>
  )
}

export default Page
